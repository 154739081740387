import global from './global.js';
import datatable from './datatable';
import paginator from './paginator';
import inputNumber from './inputNumber';
import toast from './toast';
import menu from './menu';
import password from './password';
import inputText from './inputText';
import checkbox from './checkbox';
import divider from './divider';
import button from './button';
import toggleSwitch from './toggleSwitch';
import radioButton from './radioButton';
import inputGroup from './inputGroup';
import inputGroupAddon from './inputGroupAddon';
import select from './select';
import selectbutton from './selectbutton';
import togglebutton from './togglebutton';
import inputotp from './inputotp';
import progressbar from './progressbar';

export default {
    global,
    datatable,
    paginator,
    toast,
    menu,
    inputNumber,
    password,
    inputText,
    checkbox,
    divider,
    toggleSwitch,
    radioButton,
    inputGroup,
    inputGroupAddon,
    select,
    button,
    selectbutton,
    togglebutton,
    inputotp,
    progressbar,
};
