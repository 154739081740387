<template>
    <svg fill="none" height="74" width="71" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M35.427 53.31c8.986 0 16.271-7.395 16.271-16.519 0-9.123-7.285-16.518-16.27-16.518-8.987 0-16.272 7.395-16.272 16.518 0 9.124 7.285 16.52 16.271 16.52Z"
            fill="#111928"
        />
        <path d="m29.24 47.644-7.92 4.026 2.214 4.487 7.92-4.026-2.214-4.487Z" fill="#111928" />
        <path
            d="M24.578 55.702c.47-.723-.69-2.34-2.588-3.611-1.9-1.272-3.82-1.716-4.29-.993-.47.723.69 2.34 2.589 3.611 1.9 1.272 3.82 1.716 4.29.993ZM41.614 47.644l-2.213 4.487 7.919 4.026 2.213-4.487-7.919-4.026Z"
            fill="#111928"
        />
        <path
            d="M50.565 54.708c1.9-1.271 3.058-2.888 2.589-3.611-.47-.723-2.39-.279-4.29.993-1.899 1.27-3.058 2.888-2.588 3.61.47.724 2.39.28 4.289-.992Z"
            fill="#111928"
        />
        <path
            d="M34.288 38.395c3.071 0 5.56-2.528 5.56-5.646s-2.489-5.646-5.56-5.646c-3.071 0-5.561 2.528-5.561 5.646s2.49 5.646 5.56 5.646Z"
            fill="#fff"
        />
        <path
            d="M35.64 31.668a1.901 1.901 0 0 0-.009-2.66 1.833 1.833 0 0 0-2.62-.009 1.901 1.901 0 0 0 .01 2.66 1.833 1.833 0 0 0 2.62.01Z"
            fill="#111928"
        />
        <path
            d="M48.263 18.82c.244-6.115-4.947-11.295-11.596-11.57-6.65-.274-12.237 4.462-12.482 10.577-.245 6.116 4.377 7.505 11.026 7.78 6.649.274 12.807-.671 13.052-6.787Z"
            fill="#1946B9"
        />
        <path
            d="M57.642 45.44c1.07-.932-.435-4.494-3.362-7.955-2.927-3.461-6.167-5.511-7.237-4.578-1.07.933.435 4.495 3.361 7.956 2.927 3.46 6.167 5.51 7.237 4.578ZM20.495 40.863c2.927-3.461 4.432-7.023 3.361-7.956-1.07-.933-4.31 1.117-7.237 4.578-2.926 3.461-4.431 7.023-3.361 7.956 1.07.933 4.31-1.117 7.237-4.578Z"
            fill="#111928"
        />
        <path
            d="M39.896 43.3a3.81 3.81 0 0 1-.599 2.816 3.703 3.703 0 0 1-2.384 1.561 3.663 3.663 0 0 1-2.774-.608 3.77 3.77 0 0 1-1.538-2.42l-.001-.008c-.365-2.045 1.193-2.769 3.208-3.14 2.014-.37 3.723-.246 4.088 1.8Z"
            fill="#fff"
        />
    </svg>
</template>
