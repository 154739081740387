<template>
    <div class="fixed top-0 flex w-full justify-center">
        <div class="rounded-b-lg bg-primary px-6 text-white">
            <span
                v-text="
                    __('app.administration.user.actions.impersonate.active_info', {
                        '{{ name }}': username,
                        '{{ account }}': accountName,
                    })
                "
            />
            <a
                class="text-gray hover:text-white"
                href="#"
                @click.prevent="reset"
                v-text="__('app.administration.user.actions.impersonate.leave')"
            />
        </div>
    </div>
</template>

<script>
import {useImpersonatingStore} from '../../stores/impersonatingStore';
import {mapActions} from 'pinia';

export default {
    props: {
        username: {
            type: String,
            required: true,
        },
        accountName: {
            type: String,
            required: true,
        },
    },
    methods: {
        ...mapActions(useImpersonatingStore, ['reset']),
    },
};
</script>
