<template>
    <div
        v-if="hasUnreadNotifications"
        v-cy="'mobile-notification-indicator'"
        class="absolute size-2.5 rounded-full bg-red-500"
        style="top: -4px; right: -4px"
    />
</template>

<script setup>
import {storeToRefs} from 'pinia';
import {useNotificationStore} from '../../../../stores/notificationStore';

const {hasUnreadNotifications} = storeToRefs(useNotificationStore());
</script>
