<template>
    <div
        class="group relative flex cursor-pointer items-center justify-center rounded-full tracking-wide"
    >
        <button
            class="flex size-full items-center justify-center whitespace-nowrap rounded-full text-black focus:outline-none dark:bg-black-light dark:text-white"
            type="button"
        >
            <slot name="button" />
        </button>
        <div class="absolute z-500 hidden group-hover:block" :class="openingDirectionStyles">
            <div class="rounded-md bg-background-lighter p-3 group-hover:shadow-menu dark:bg-black">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        opensTowards: {
            type: String,
            default: 'bottom',
        },
    },
    computed: {
        openingDirectionStyles() {
            switch (this.opensTowards) {
                case 'bottom':
                    return 'bottom-0 right-0 pt-3 translate-y-full';
                case 'right':
                    return 'right-0 bottom-0 pl-3 translate-x-full';
                case 'top':
                    return 'top-0 left-0 pb-3 -translate-y-full';
                default:
                    return 'left-0 bottom-0 pr-3 -translate-x-full';
            }
        },
    },
};
</script>
