<template>
    <div v-if="flagActive">
        <template v-if="snowActive">
            <svg
                class="fill-current hover:text-hover"
                height="20"
                viewBox="0 0 448 512"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                @click="deactivateSnow"
            >
                <path
                    d="M416 224c0 49.2-18.5 94-48.9 128l40.7 0c25.3-36.3 40.2-80.4 40.2-128C448 100.3 347.7 0 224 0S0 100.3 0 224c0 47.6 14.8 91.7 40.2 128l40.7 0C50.5 318 32 273.2 32 224C32 118 118 32 224 32s192 86 192 192zM240 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm-27.3 44.7l-64 64c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L208 198.6l0 34.7-59.3 59.3c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L208 278.6l0 73.4 32 0 0-73.4 36.7 36.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L240 233.4l0-34.7 36.7 36.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-64-64c-6.2-6.2-16.4-6.2-22.6 0zM360 176a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM80 264a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM50.3 391.8l-46.2 77C1.4 473.2 0 478.3 0 483.5C0 499.2 12.8 512 28.5 512l390.9 0c15.8 0 28.5-12.8 28.5-28.5c0-5.2-1.4-10.2-4.1-14.7l-46.2-77c-2.9-4.8-8.1-7.8-13.7-7.8L64 384c-5.6 0-10.8 2.9-13.7 7.8zM73.1 416l301.9 0 38.4 64L34.7 480l38.4-64z"
                />
            </svg>
        </template>

        <template v-else>
            <svg
                class="fill-current hover:text-hover"
                height="20"
                viewBox="0 0 512 512"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                @click="activateSnow"
            >
                <path
                    d="M240 104c0 8.8 7.2 16 16 16s16-7.2 16-16l0-88c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 88zm16 88a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 160a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm0 160c8.8 0 16-7.2 16-16l0-80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 80c0 8.8 7.2 16 16 16zM0 256c0 8.8 7.2 16 16 16l80 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-80 0c-8.8 0-16 7.2-16 16zm408-16c-8.8 0-16 7.2-16 16s7.2 16 16 16l88 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-88 0zM75 437c6.2 6.2 16.4 6.2 22.6 0l56.6-56.6c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L75 414.4c-6.2 6.2-6.2 16.4 0 22.6zM352.2 137.2c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L437 97.6c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-62.2 62.2zM75 75c-6.2 6.2-6.2 16.4 0 22.6l56.6 56.6c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L97.6 75c-6.2-6.2-16.4-6.2-22.6 0zM374.8 352.2c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L414.4 437c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-62.2-62.2z"
                />
            </svg>
        </template>

        <!-- eslint-disable-next-line vue/no-undef-components-->
        <snow-effect v-if="snowActive" color="#ddd" flakes="100" speed="1" />
    </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import API from '../../../api/api.js';
import {useStorage} from '@vueuse/core';
import {storeToRefs} from 'pinia';
import {useUserStore} from '../../../stores/userStore.js';

const flagActive = ref(false);
const snowActive = useStorage('snow', false);
const {user} = storeToRefs(useUserStore());
const userEmail = computed(() => user.value?.email);

/**
 * Makes an API call to check the snow flag status.
 * If the response status is 201 and the user's email includes '@desk.ly',
 * it sets the flagActive to true and adds a script to the head.
 */
API.get('/api/flag/snow').then(response => {
    if (response.status === 201 && userEmail?.value?.includes('@desk.ly')) {
        flagActive.value = true;
        addScriptToHead();
    }
});

function activateSnow() {
    API.get('/api/flag/snow').then(response => {
        if (response.status === 201) {
            snowActive.value = true;
        }
    });
}

function deactivateSnow() {
    snowActive.value = false;
}

/**
 * Adds a script element to the document head to load the snow effect module.
 * The script is loaded from the specified URL.
 */
function addScriptToHead() {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@le-pepe/snow-effect';

    document.head.appendChild(script);
}
</script>
