<template>
    <prime-vue-progress-bar :value="value">
        <slot v-if="hasTemplate"></slot>
        <span v-else />
    </prime-vue-progress-bar>
</template>
<script setup lang="ts">
import PrimeVueProgressBar from 'primevue/progressbar';
import {computed, useSlots} from 'vue';

type Props = {
    value: number;
};
defineProps<Props>();

const slots = useSlots();
const hasTemplate = computed(() => !!slots.default);
</script>
