import {FilterMatchMode, FilterOperator} from '@primevue/core/api';

const DataTypes = {
    TEXT: 'text',
    NUMERIC: 'numeric',
    DATE: 'date',
    BOOLEAN: 'boolean',
};

export const getFilterQueryParams = filter =>
    Object.keys(filter)
        .map(key => ({key, filterElement: filter[key]}))
        .map(serializeFilterElement)
        .filter(Boolean);

export const getDefaultOperatorForDataType = dataType => {
    switch (dataType?.toLowerCase()) {
        case DataTypes.TEXT:
            return FilterMatchMode.CONTAINS;
        case DataTypes.NUMERIC:
            return FilterMatchMode.EQUALS;
        case DataTypes.BOOLEAN:
            return FilterMatchMode.EQUALS;
        case DataTypes.DATE:
            return FilterMatchMode.EQUALS;
        default:
            return null;
    }
};

const serializeChainedFilter = (key, element) => {
    const {filterOperator, constraints} = element;

    if (!filterOperator && !constraints) {
        return;
    }

    return constraints
        .map(constraint => serializeFilterElement({key, filterElement: constraint}))
        .filter(Boolean);
};

const serializeFilterElement = ({key, filterElement}) => {
    if (filterElement.filterOperator) {
        return serializeChainedFilter(key, filterElement);
    }

    let value = filterElement.value;
    const {matchMode, valueAccessor} = filterElement;

    if (valueAccessor && value) {
        value = valueAccessor(value);
    }

    if (!matchMode || !value) {
        return null;
    }

    const template = getTemplate(matchMode, value);

    if (!template) {
        return null;
    }

    return {key, value: template};
};

const getTemplate = (matchMode, value) => {
    if (
        value === undefined ||
        (value === null &&
            (matchMode !== FilterMatchMode.EQUALS || matchMode !== FilterMatchMode.NOT_EQUALS))
    ) {
        return null;
    }
    switch (matchMode) {
        case FilterMatchMode.CONTAINS:
            return `*${value}*`;
        case FilterMatchMode.STARTS_WITH:
            return `${value}*`;
        case FilterMatchMode.NOT_CONTAINS:
            return `-${value}`;
        case FilterMatchMode.ENDS_WITH:
            return `*${value}`;
        case FilterMatchMode.EQUALS:
            return value;
        case FilterMatchMode.NOT_EQUALS:
            return `!${value}`;
        case FilterMatchMode.LESS_THAN:
            return `<${value}`;
        case FilterMatchMode.LESS_THAN_OR_EQUAL_TO:
            return `<=${value}`;
        case FilterMatchMode.GREATER_THAN:
            return `>${value}`;
        case FilterMatchMode.GREATER_THAN_OR_EQUAL_TO:
            return `>=${value}`;
        case FilterMatchMode.DATE_IS:
            return getDateValue(value);
        case FilterMatchMode.DATE_IS_NOT:
            return `!${getDateValue(value)}`;
        case FilterMatchMode.DATE_BEFORE:
            return `<=${getDateValue(value)}`;
        case FilterMatchMode.DATE_AFTER:
            return `>=${getDateValue(value)}`;
        default:
            return null;
    }
};

const getDateValue = value =>
    typeof value === 'object' && value?.toISOString ? value.toISOString() : value;

export const buildFilterFromColumns = columnComponents =>
    (columnComponents ?? []).reduce(
        (acc, curr) => {
            const key = curr.props.field;
            const dataType = curr.props['data-type'] ?? 'text';
            acc[key] = {
                value: null,
                matchMode: getDefaultOperatorForDataType(dataType),
                dataType,
            };
            return acc;
        },
        {search: {value: null, matchMode: FilterMatchMode.CONTAINS}}
    );
export const getDateRangeFilter = () => ({
    filterOperator: FilterOperator.AND,
    constraints: [
        {value: null, matchMode: FilterMatchMode.DATE_AFTER},
        {value: null, matchMode: FilterMatchMode.DATE_BEFORE},
    ],
});

export const getMultiSelectFilter = (values = []) => ({
    filterOperator: FilterOperator.AND,
    constraints: [values.map(value => ({value, matchMode: FilterMatchMode.EQUALS}))],
});
