<template>
    <div class="relative flex items-center justify-center rounded-full tracking-wide">
        <button
            class="flex size-full items-center justify-center whitespace-nowrap rounded-full text-black focus:outline-none"
            type="button"
            @click="openContent()"
        >
            <slot name="button" />
        </button>
        <div
            ref="dropdownContent"
            class="absolute z-500"
            :class="[openingDirectionStyles, {hidden: !active}]"
        >
            <div class="rounded-md bg-background-lighter p-3 shadow-menu">
                <slot :close="closeContent" name="content" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {onClickOutside} from '@vueuse/core';
import {computed, onMounted, ref} from 'vue';

const props = defineProps({
    opensTowards: {
        type: String,
        default: 'bottom',
    },
});

const emits = defineEmits(['open', 'close']);

const active = ref(false);
const dropdownContent = ref(null);

const openingDirectionStyles = computed(() => {
    switch (props.opensTowards) {
        case 'bottom':
            return 'bottom-0 right-0 pt-3 translate-y-full';
        case 'right':
            return 'right-0 bottom-0 pl-3 translate-x-full';
        case 'top':
            return 'top-0 left-0 pb-3 -translate-y-full';
        default:
            return 'left-0 bottom-0 pr-3 -translate-x-full';
    }
});

onMounted(() => onClickOutside(dropdownContent, closeContent));

function openContent() {
    active.value = true;
    emits('open');
}
function closeContent() {
    if (!active.value) {
        return;
    }
    active.value = false;
    emits('close');
}
</script>
