import API from '../api/api';
import {computed, type ComputedRef, ref} from 'vue';
import {computedAsync, until, useMemoize} from '@vueuse/core';
import {defineStore} from 'pinia';
import {useUserStore} from './userStore';

type FeatureFlagState = {
    loading: boolean;
    isActive: boolean;
    isReady: () => Promise<boolean>;
};

function useFeatureFlag(endpoint: string, lazy = false): ComputedRef<FeatureFlagState> {
    const userStore = useUserStore();

    const loadFeatureFlagMemoized = useMemoize(_ => API.get(endpoint));

    const loading = ref(false);
    const isActive = computedAsync(
        async () => {
            if (!userStore.isAuthenticated || !userStore.user?.id) {
                return false;
            }

            const response = await loadFeatureFlagMemoized.load(userStore.user?.id);
            return response.status === 201;
        },
        null,
        {
            lazy,
            evaluating: loading,
        }
    );

    /**
     * Returns a promise that resolves either
     * (1) immediately when isLoading is false
     * (2) as soon as isLoading is false and a new value is loaded
     */
    async function isReady(): Promise<boolean> {
        // Ensure that lazy-loaded values will be read
        await until(isActive).not.toBeNull();
        await until(loading).not.toBeTruthy();
        return isActive.value ?? false;
    }

    return computed(() => ({
        isActive: isActive.value ?? false,
        loading: loading.value,
        isReady,
    }));
}

export const useFeatureFlagStore = defineStore('featureFlags', () => {
    const optimizedSeatDayBooking = useFeatureFlag('/api/flag/optimized-seat-day-booking');
    const sendNotifications = useFeatureFlag('/api/flag/send-notifications', true);
    const receiveNotifications = useFeatureFlag('/api/flag/receive-notifications');
    const newBooking = useFeatureFlag('/api/flag/new-booking');
    const showShigotoActions = useFeatureFlag('/api/flag/show-shigoto-actions');
    const kioskMode = useFeatureFlag('/api/flag/kiosk-mode');

    return {
        optimizedSeatDayBooking,
        sendNotifications,
        receiveNotifications,
        newBooking,
        showShigotoActions,
        kioskMode,
    };
});
