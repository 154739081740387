<template>
    <div class="size-8">
        <img
            v-if="imageUrl"
            alt="avatar"
            class="size-full rounded-full ring-2 ring-gray"
            :src="imageUrl"
        />
        <user-icon v-else class="h-8 text-gray dark:text-white" :initials="user?.initials" />
    </div>
</template>

<script>
import UserIcon from '../../../../shared/components/icons/UserIcon.vue';
import userHelper from '../../../mixins/userHelper';
import {useLocaleStore} from '../../../../stores/localeStore';
import {mapState} from 'pinia';
import {useUserStore} from '../../../../stores/userStore';
import {useAccountStore} from '../../../../stores/accountStore';

export default {
    components: {
        UserIcon,
    },
    mixins: [userHelper],
    props: {
        preview: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState(useLocaleStore, ['currentLocale']),
        ...mapState(useUserStore, ['user']),
        ...mapState(useAccountStore, ['account']),
        imageUrl() {
            return this.preview ?? this.avatarUrl;
        },
        useKomboAvatar() {
            return (
                this.account?.komboADIntegration &&
                this.account?.useKomboAvatar &&
                this.user?.isKomboUser
            );
        },
        avatarUrl() {
            if (!this.user) {
                return null;
            }

            if (this.useKomboAvatar) {
                return this.user.komboAvatarUrl;
            }

            if (!this.account.allowAvatar) {
                return null;
            }

            const gravatar = this.user.gravatar;
            if (this.account.allowGravatar && gravatar) {
                return gravatar;
            }

            if (this.user.avatarName) {
                // small trick to reload image
                return `${this.user.profileImage}?rnd=${Math.random()}`;
            }

            return null;
        },
    },
};
</script>
